export const LanguageSuggestionBannerDialog = {
    modelContainer: {
        textAlign: 'center',
        padding: '4.4rem 6.4rem',
    },
    modalDialog: {
        textAlign: 'center',
        maxWidth: '78rem !important',
        margin: 'auto',
    },
    descriptionContainer: {
        margin: '3rem 0',
    },
    chooseLanguage: {
        textAlign: 'center',
        margin: 'auto',
        marginTop: '0.6rem',
    },
    closeIcon: {
        position: 'absolute',
        top: '2.7rem',
        right: '2.4rem',
    },
    regionIcon: {
        margin: 'auto !important',
    },
    moveTextTransform: {
        transform: 'translateX(0)',
    },
    languageButton: {
        marginBottom: '2.4rem',
    },
    stayLanguageButton: {
        marginBottom: '0.5rem',
    },
    regionButtonContainer: {
        flexDirection: 'column',
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
    },
} as const;
