import { httpGet } from './apiService';
import { getLanguageByCode } from '@helpers/languages';

export interface LanguageBanner {
    showBanner: boolean;
    relativeUrl: string;
    acceptLanguage: string;
}

export async function getLanguageBanner(fullUrl: string, language: string): Promise<LanguageBanner> {
    const languageId = getLanguageByCode(language)?.id;
    const languageBanner = await httpGet(`/api/language-banner/?fullUrl=${fullUrl}&languageId=${languageId}`);
    return languageBanner.json();
}
