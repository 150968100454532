import { CookieKey } from '@models/cookie';
import { getCookie } from './cookieService';

const defaultHeaders: HeadersInit = { Accept: 'application/json', 'Content-Type': 'application/json' };

interface ErrorBody {
    Message?: string;
    StackTrace?: string;
    StatusCode?: number;
}

export function httpGet(
    url: string,
    headers: HeadersInit | undefined = undefined,
    cache: RequestCache | null = null,
    returnOriginalError = false,
    includeAuthorizationHeader = true
): Promise<Response> {
    const httpMethod = 'GET';
    const requestOptions = generateRequestOptions(httpMethod, headers, null, cache, includeAuthorizationHeader);
    return fetch(url, requestOptions).then(
        async (response) => await handleResponse(response, httpMethod, returnOriginalError)
    );
}

export function httpPost(
    url: string,
    body: string | FormData | null = null,
    headers: HeadersInit | undefined = defaultHeaders
): Promise<Response> {
    const httpMethod = 'POST';
    const requestOptions = generateRequestOptions(httpMethod, headers, body);
    return fetch(url, requestOptions).then(async (response) => await handleResponse(response, httpMethod));
}

export function httpPut(url: string, body: string | null = null, headers = defaultHeaders): Promise<Response> {
    const httpMethod = 'PUT';
    const requestOptions = generateRequestOptions(httpMethod, headers, body);
    return fetch(url, requestOptions).then(async (response) => await handleResponse(response, httpMethod));
}

export function httpPatch(
    url: string,
    body: string | null = null,
    headers: HeadersInit | undefined = defaultHeaders
): Promise<Response> {
    const httpMethod = 'PATCH';
    const requestOptions = generateRequestOptions(httpMethod, headers, body);
    return fetch(url, requestOptions).then(async (response) => await handleResponse(response, httpMethod));
}

export function httpDelete(url: string, headers: HeadersInit | undefined = undefined): Promise<Response> {
    const httpMethod = 'DELETE';
    const requestOptions = generateRequestOptions(httpMethod, headers);
    return fetch(url, requestOptions).then(async (response) => await handleResponse(response, httpMethod));
}

async function handleResponse(response: Response, httpMethod: string, returnOriginalError = false): Promise<Response> {
    if (!response.ok) {
        let errorBody: ErrorBody = {};
        const text = await response.text();
        try {
            if (typeof text === 'string') {
                errorBody.Message = extractMessageFromText(text);
                errorBody.StatusCode = response.status;
            } else {
                errorBody = JSON.parse(text);
                errorBody.Message = errorBody.StackTrace?.split('\n')[0];
            }
        } catch (e) {
            errorBody = {
                Message: 'An unknown error occurred.',
                StackTrace: '',
                StatusCode: response.status,
            };
        }

        console.error(`${httpMethod} failed with status code ${errorBody.StatusCode}: ${errorBody.Message}`);
        if (returnOriginalError) {
            throw { response, errorBody };
        }
        throw new Error(`Failed to ${httpMethod} with status code ${errorBody.StatusCode}: ${errorBody.Message}`);
    }

    return response;
}

function extractMessageFromText(text: string): string {
    try {
        const errorBody = JSON.parse(text);
        return errorBody.message || text;
    } catch {
        return text;
    }
}

function generateRequestOptions(
    httpMethod: string,
    headers: HeadersInit | undefined = undefined,
    body: string | FormData | null = null,
    cache: RequestCache | null = null,
    includeAuthorizationHeader = true
): RequestInit {
    const requestOptions: RequestInit = { method: httpMethod };
    const jwtCookieValue = getCookie(CookieKey.CmsJwtToken);

    if (body) {
        requestOptions.body = body;
    }

    if (headers) {
        requestOptions.headers = headers;
    }

    if (includeAuthorizationHeader && jwtCookieValue) {
        requestOptions.headers = { ...requestOptions.headers, Authorization: `Bearer ${jwtCookieValue}` };
    }

    if (cache) {
        requestOptions.cache = cache;
    }

    return requestOptions;
}
