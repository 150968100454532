import React from 'react';

export function LanguageRegion(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            aria-hidden
            width='125'
            height='99'
            viewBox='0 0 125 99'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <path
                d='M66.8607 98.5502C86.1894 98.5502 101.858 82.9878 101.858 63.7907C101.858 44.5935 86.1894 29.0312 66.8607 29.0312C47.532 29.0312 31.863 44.5935 31.863 63.7907C31.863 82.9878 47.532 98.5502 66.8607 98.5502Z'
                fill='#EAEDF1'
            />
            <mask id='mask0_41_1413' maskUnits='userSpaceOnUse' x='31' y='29' width='71' height='70'>
                <path
                    d='M66.8607 98.5502C86.1894 98.5502 101.858 82.9878 101.858 63.7907C101.858 44.5935 86.1894 29.0312 66.8607 29.0312C47.532 29.0312 31.863 44.5935 31.863 63.7907C31.863 82.9878 47.532 98.5502 66.8607 98.5502Z'
                    fill='white'
                />
            </mask>
            <g mask='url(#mask0_41_1413)'>
                <path
                    d='M58.6149 37.137L59.8912 34.106C61.7594 29.6683 57.8939 25.4543 53.6114 27.6991C41.6157 33.9873 25.0241 48.4203 34.1524 78.2626C38.7423 89.2884 40.4662 95.3497 41.0756 98.6052C41.48 100.767 42.0384 103.427 43.9387 104.555C45.793 105.655 47.9394 105.494 47.9394 98.8462C47.9394 89.0097 56.2829 78.0043 58.9422 74.7464C59.465 74.1064 59.8741 73.383 60.1215 72.5964L61.6225 67.8246C63.0134 63.4018 58.9161 59.0789 54.2711 59.4996C47.8619 60.0798 41.2418 59.4103 43.11 53.66C44.1856 50.349 46.7309 49.3658 49.7166 49.5753C54.5524 49.9146 60.4155 45.2588 58.5948 40.7955C58.1155 39.6208 58.1231 38.3058 58.6149 37.137Z'
                    fill='#C1C9D6'
                />
                <path
                    d='M86.8306 51.9027L93.0161 46.5864C94.9884 44.8912 97.8313 44.6754 99.8681 46.2939C108.484 53.1419 128.216 73.0096 104.744 97.1574C97.7728 103.527 93.9212 107.476 91.7981 109.868C90.1016 111.779 87.4799 113.299 84.9152 113.355L71.6323 113.644C65.8563 113.769 63.2259 106.33 67.6632 102.655C69.2809 101.315 70.8377 99.9871 72.2117 98.7535C76.7439 94.6842 80.7198 91.8737 83.4281 87.9207C85.1076 85.4699 86.0229 81.4926 86.476 78.824C86.7732 77.0738 86.1147 75.3267 84.8349 74.0876L79.9445 69.3523C77.808 67.2836 77.5496 63.9613 79.3407 61.5911L85.9671 52.8219C86.2211 52.4857 86.5108 52.1779 86.8306 51.9027Z'
                    fill='#C1C9D6'
                />
                <path
                    d='M69.8326 37.1166L70.947 29.8484C71.3945 26.9309 73.9442 24.7919 76.9149 24.8414L78.5896 24.8694C79.6149 24.8867 80.6181 25.167 81.5018 25.6839L83.1353 26.6392C86.0862 28.3647 86.9596 32.2052 85.0423 35.0228L80.7206 41.3723C78.9177 44.0212 75.3178 44.7599 72.6054 43.0376L72.5041 42.9731C70.5263 41.7171 69.4788 39.4216 69.8326 37.1166Z'
                    fill='#C1C9D6'
                />
            </g>
            <path
                opacity='0.1'
                fillRule='evenodd'
                clipRule='evenodd'
                d='M52.9961 92.0376C72.33 92.0376 88.0036 76.4707 88.0036 57.2682C88.0036 46.1339 82.7341 36.2218 74.5366 29.8582C90.1749 33.3362 101.861 47.2067 101.861 63.7875C101.861 82.99 86.1876 98.5569 66.8532 98.5569C58.7297 98.5569 51.2525 95.8089 45.3127 91.1974C47.7851 91.7474 50.3566 92.0376 52.9961 92.0376Z'
                fill='#112E5A'
            />
            <path
                d='M47.668 10.6367C47.668 14.243 44.7246 17.1665 41.0934 17.1665C37.4623 17.1665 34.5188 14.243 34.5188 10.6367C34.5188 7.03047 37.4623 4.1069 41.0934 4.1069C44.7246 4.1069 47.668 7.03047 47.668 10.6367Z'
                stroke='#D8DEE6'
                strokeWidth='1.5'
            />
            <path
                d='M100.683 20.0334C100.683 21.2966 99.6521 22.3207 98.3799 22.3207C97.108 22.3207 96.0768 21.2966 96.0768 20.0334C96.0768 18.7701 97.108 17.7459 98.3799 17.7459C99.6521 17.7459 100.683 18.7701 100.683 20.0334Z'
                stroke='#D8DEE6'
                strokeWidth='1.5'
            />
            <path
                d='M61.6815 76.3721C61.6815 77.6352 60.6506 78.6593 59.3784 78.6593C58.1065 78.6593 57.0752 77.6352 57.0752 76.3721C57.0752 75.1086 58.1065 74.0844 59.3784 74.0844C60.6506 74.0844 61.6815 75.1086 61.6815 76.3721Z'
                stroke='white'
                strokeWidth='1.5'
            />
            <path
                d='M28.8364 76.3721C28.8364 77.6352 27.8053 78.6593 26.5332 78.6593C25.2611 78.6593 24.2299 77.6352 24.2299 76.3721C24.2299 75.1086 25.2611 74.0844 26.5332 74.0844C27.8053 74.0844 28.8364 75.1086 28.8364 76.3721Z'
                stroke='#D8DEE6'
                strokeWidth='1.5'
            />
            <path
                d='M123.81 28.803C123.81 30.0662 122.78 31.0903 121.507 31.0903C120.235 31.0903 119.204 30.0662 119.204 28.803C119.204 27.5395 120.235 26.5154 121.507 26.5154C122.78 26.5154 123.81 27.5395 123.81 28.803Z'
                stroke='#D8DEE6'
                strokeWidth='1.5'
            />
            <path
                d='M83.9972 41.1427C83.9972 42.4063 82.9663 43.4304 81.6941 43.4304C80.4222 43.4304 79.391 42.4063 79.391 41.1427C79.391 39.8796 80.4222 38.8555 81.6941 38.8555C82.9663 38.8555 83.9972 39.8796 83.9972 41.1427Z'
                stroke='white'
                strokeWidth='1.5'
            />
            <path d='M51.8595 31.3313V36.4958' stroke='white' strokeWidth='1.5' />
            <path d='M54.4598 33.9138H49.2596' stroke='white' strokeWidth='1.5' />
            <path d='M109.661 8.05435V13.219' stroke='#D8DEE6' strokeWidth='1.5' />
            <path d='M112.261 10.6367H107.061' stroke='#D8DEE6' strokeWidth='1.5' />
            <path d='M104.461 43.6434V48.8084' stroke='#D8DEE6' strokeWidth='1.5' />
            <path d='M107.061 46.2259H101.861' stroke='#D8DEE6' strokeWidth='1.5' />
            <path d='M14.6613 84.7973V89.9618' stroke='#D8DEE6' strokeWidth='1.5' />
            <path d='M17.2614 87.3798H12.0614' stroke='#D8DEE6' strokeWidth='1.5' />
            <path d='M12.287 1.06009V6.22475' stroke='#D8DEE6' strokeWidth='1.5' />
            <path d='M14.887 3.64243H9.68698' stroke='#D8DEE6' strokeWidth='1.5' />
            <path d='M85.9085 77.1474V80.3541' stroke='white' strokeWidth='1.5' />
            <path d='M87.5231 78.7509H84.294' stroke='white' strokeWidth='1.5' />
            <path
                d='M16.0186 47.0566C24.7608 47.0566 31.8477 40.0161 31.8477 31.3313C31.8477 22.6464 24.7608 15.606 16.0186 15.606C7.2765 15.606 0.189606 22.6464 0.189606 31.3313C0.189606 40.0161 7.2765 47.0566 16.0186 47.0566Z'
                fill='#EAEDF1'
            />
            <path
                opacity='0.1'
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9.96244 44.1093C18.6217 44.1093 25.6414 37.0719 25.6414 28.3907C25.6414 23.357 23.2813 18.8761 19.6099 15.9992C26.6138 17.5716 31.8477 23.8422 31.8477 31.338C31.8477 40.0192 24.828 47.0566 16.1687 47.0566C12.5304 47.0566 9.18156 45.8143 6.52121 43.7296C7.62861 43.9784 8.78025 44.1093 9.96244 44.1093Z'
                fill='#112E5A'
            />
            <path
                d='M22.9438 36.1471C23.9819 36.1471 24.8235 35.3111 24.8235 34.2798C24.8235 33.2484 23.9819 32.4124 22.9438 32.4124C21.9057 32.4124 21.0641 33.2484 21.0641 34.2798C21.0641 35.3111 21.9057 36.1471 22.9438 36.1471Z'
                stroke='white'
                strokeWidth='1.5'
            />
            <path
                d='M19.3823 22.7806C20.4204 22.7806 21.262 21.9446 21.262 20.9132C21.262 19.8819 20.4204 19.0459 19.3823 19.0459C18.3442 19.0459 17.5026 19.8819 17.5026 20.9132C17.5026 21.9446 18.3442 22.7806 19.3823 22.7806Z'
                stroke='white'
                strokeWidth='1.5'
            />
            <path
                d='M6.71908 42.0441C7.75721 42.0441 8.59878 41.2081 8.59878 40.1767C8.59878 39.1454 7.75721 38.3094 6.71908 38.3094C5.68096 38.3094 4.83939 39.1454 4.83939 40.1767C4.83939 41.2081 5.68096 42.0441 6.71908 42.0441Z'
                stroke='white'
                strokeWidth='1.5'
            />
            <path
                d='M9.48913 31.8227C11.8386 31.8227 13.7432 29.9306 13.7432 27.5965C13.7432 25.2625 11.8386 23.3703 9.48913 23.3703C7.13968 23.3703 5.23508 25.2625 5.23508 27.5965C5.23508 29.9306 7.13968 31.8227 9.48913 31.8227Z'
                stroke='white'
                strokeWidth='1.5'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M68.2544 0.869476C65.9404 0.870374 63.7213 1.78614 62.085 3.41544C60.449 5.04475 59.5294 7.25427 59.5291 9.55824C59.5291 14.3551 68.2544 23.4686 68.2544 23.4686C68.2544 23.4686 76.9798 14.3551 76.9798 9.55824C76.9795 7.25427 76.0599 5.04475 74.4239 3.41544C72.7876 1.78614 70.5685 0.870374 68.2544 0.869476ZM68.2544 12.8373C67.6032 12.8373 66.9662 12.6449 66.4246 12.2846C65.883 11.9243 65.4611 11.4121 65.2118 10.813C64.9625 10.2138 64.8974 9.55449 65.0245 8.91844C65.1516 8.28235 65.4651 7.69811 65.9257 7.23953C66.3863 6.78096 66.9734 6.46868 67.6122 6.34216C68.251 6.21567 68.9132 6.28062 69.5149 6.5288C70.1167 6.77699 70.6312 7.19728 70.993 7.7365C71.3551 8.27575 71.5483 8.90971 71.5483 9.55824C71.5473 10.4277 71.1999 11.2612 70.5826 11.876C69.9649 12.4907 69.1276 12.8365 68.2544 12.8373Z'
                fill='#0078D3'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M74.1752 9.69398C74.1755 8.56127 73.8377 7.45388 73.2045 6.51187C72.5713 5.56988 71.6711 4.83556 70.6178 4.40181C69.5645 3.96803 68.4056 3.8543 67.2872 4.07499C66.1688 4.29566 65.1414 4.84086 64.3349 5.64161C63.5284 6.44234 62.979 7.46267 62.7562 8.57359C62.5337 9.68448 62.6475 10.8361 63.0834 11.8827C63.5197 12.9293 64.2582 13.824 65.2062 14.4536C66.1541 15.0831 67.2685 15.4193 68.4087 15.4196C69.9369 15.418 71.4024 14.8143 72.4834 13.741C73.5641 12.6677 74.1727 11.2123 74.1752 9.69398ZM68.4103 12.7384C67.8042 12.7384 67.2118 12.5598 66.7079 12.2253C66.2037 11.8907 65.811 11.4152 65.5792 10.8589C65.3473 10.3026 65.2866 9.69052 65.4047 9.09997C65.5231 8.50942 65.8151 7.96697 66.2436 7.54121C66.672 7.11545 67.2183 6.82553 67.8126 6.70808C68.4071 6.59059 69.0232 6.6509 69.5832 6.88135C70.1432 7.11177 70.6218 7.50199 70.9584 8.00264C71.2952 8.50329 71.475 9.09189 71.475 9.69398C71.4741 10.5012 71.151 11.2751 70.5763 11.8459C70.0017 12.4166 69.2227 12.7376 68.4103 12.7384Z'
                fill='#0059B3'
            />
        </svg>
    );
}
