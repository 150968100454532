import React, { startTransition, useCallback, useEffect, useState } from 'react';
import { Grid, IconButton, Dialog, Button } from '@mui/material';
import { LanguageSuggestionBannerDialog as classes } from './styleObjects/LanguageSuggestionBannerDialog';
import { CMSLink } from '@components/CMSLink';
import { getCookie, setCookie } from '@services/cookieService';
import { CloseIcon } from '@icon/CloseIcon';
import { LanguageRegion } from '@icon/LanguageRegion';
import { languageBannerTranslations } from '@helpers/languageSuggestionBannerHelper';
import { useAtom, useSetAtom } from 'jotai';
import { selectedLanguageAtom } from '@atoms/appSettings';
import { useStaticValues } from '@stateManagement/StaticValuesContext';
import { getLanguageBanner } from '@services/languageBannerPublicService';
import { Text } from '@web-for-marketing/react-ui';
import { logError } from '@helpers/errors';
import { isRegionSelectionDialogOpenAtom } from '@atoms/appSettings';
import { CookieKey } from '@models/cookie';

export default function LanguageSuggestionBanner(): JSX.Element {
    const [selectedLanguage] = useAtom(selectedLanguageAtom);
    const { fullUrl, notFound } = useStaticValues();
    const [showBanner, setShowBanner] = useState(false);
    const [relativeUrl, setRelativeUrl] = useState('');
    const [bannerText, setBannerText] = useState('');
    const [redirectButtonText, setRedirectButtonText] = useState('');
    const [stayedirectButtonText, setStayRedirectButtonText] = useState('');
    const [languageRegion, setLanguageRegion] = useState('');
    const [chooseBannerText, setChooseBannerText] = useState('');
    const setIsRegionSelectionDialogOpen = useSetAtom(isRegionSelectionDialogOpenAtom);

    const getData = useCallback((fullUrl: string, language: string) => {
        if (!getCookie(CookieKey.LanguageSuggestionBanner)) {
            const encodedUrl = encodeURIComponent(fullUrl.split('?')[0]);
            getLanguageBanner(encodedUrl, language)
                .then((languageBanner) => {
                    if (languageBanner.showBanner) {
                        setRelativeUrl(languageBanner.relativeUrl);
                        const primaryTranslation = languageBannerTranslations[languageBanner.acceptLanguage];
                        const stayTranslation = languageBannerTranslations[language];
                        const defaultTranslation = languageBannerTranslations['en-us'];
                        const translation = primaryTranslation ?? defaultTranslation;
                        setBannerText(translation.pageText);
                        setRedirectButtonText(translation.redirectButtonText);
                        setChooseBannerText(translation.chooseBannerText);
                        setLanguageRegion(translation.languageRegion);
                        setStayRedirectButtonText(
                            stayTranslation
                                ? stayTranslation.stayRedirectButtonText
                                : defaultTranslation.stayRedirectButtonText
                        );
                        setShowBanner(languageBanner.showBanner);
                    }
                })
                .catch((error) => logError(error, 'get/set language banner'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (fullUrl && !notFound) {
            getData(fullUrl, selectedLanguage);
        }
    }, [fullUrl, notFound, selectedLanguage, getData]);

    const setCookieAndCloseBanner = (): void => {
        setCookie(CookieKey.LanguageSuggestionBanner, true, 1);
        setShowBanner(false);
    };

    const handleRegionSelectorOpen = (): void => {
        setCookieAndCloseBanner();
        startTransition(() => {
            setIsRegionSelectionDialogOpen(true);
        });
    };

    return (
        <Dialog
            onClose={setCookieAndCloseBanner}
            open={showBanner}
            fullWidth
            maxWidth='md'
            css={classes.modalDialog}
            aria-labelledby='language-suggestion-title'
        >
            <Grid container alignItems='center' css={classes.modelContainer}>
                <IconButton aria-label='close' onClick={setCookieAndCloseBanner} size='large' css={classes.closeIcon}>
                    <CloseIcon />
                </IconButton>
                <Grid item sm={12} css={classes.regionIcon}>
                    <LanguageRegion />
                </Grid>
                <Grid item sm={12} css={classes.descriptionContainer}>
                    <Text variant='body1' id='language-suggestion-title'>
                        {bannerText} {languageRegion}
                    </Text>
                </Grid>
                <div css={classes.regionButtonContainer}>
                    <CMSLink
                        href={relativeUrl}
                        css={classes.languageButton}
                        type='button'
                        variant='contained'
                        color='primary'
                    >
                        {redirectButtonText}
                    </CMSLink>
                    <Button variant='outlined' css={classes.stayLanguageButton} onClick={setCookieAndCloseBanner}>
                        {stayedirectButtonText}
                    </Button>
                </div>
                <Grid item sm={12} css={classes.chooseLanguage}>
                    <Button variant='text' css={classes.moveTextTransform} onClick={handleRegionSelectorOpen}>
                        {chooseBannerText}
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    );
}
