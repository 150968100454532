import { getLanguageNameByCode } from '@helpers/languages';
import { LanguageCode } from '@models/languageCode';

export interface LanguageBannerTransalation {
    pageText: string;
    redirectButtonText: string;
    languageRegion: string;
    stayRedirectButtonText: string;
    chooseBannerText: string;
}

const en: LanguageBannerTransalation = {
    pageText: 'Would you like to see our site in your preferred region:',
    redirectButtonText: `Go to ${getLanguageNameByCode(LanguageCode.en)}`,
    languageRegion: `${getLanguageNameByCode(LanguageCode.en)}`,
    stayRedirectButtonText: `Stay on ${getLanguageNameByCode(LanguageCode.en)}`,
    chooseBannerText: 'Choose another region/language',
};

const uk: LanguageBannerTransalation = {
    pageText: 'Would you like to see our site in your preferred region:',
    redirectButtonText: `Go to ${getLanguageNameByCode(LanguageCode.enGb)}`,
    languageRegion: `${getLanguageNameByCode(LanguageCode.enGb)}`,
    stayRedirectButtonText: `Stay on ${getLanguageNameByCode(LanguageCode.enGb)}`,
    chooseBannerText: 'Choose another region/language',
};

const frCa: LanguageBannerTransalation = {
    pageText: 'Aimeriez-vous consulter notre site dans votre région de préférence:',
    redirectButtonText: `Aller à  ${getLanguageNameByCode(LanguageCode.frCa)}`,
    languageRegion: `${getLanguageNameByCode(LanguageCode.frCa)}`,
    stayRedirectButtonText: `Restez sur le site de ${getLanguageNameByCode(LanguageCode.frCa)}`,
    chooseBannerText: 'Choisissez une autre région ou langue',
};

const es: LanguageBannerTransalation = {
    pageText: '¿Prefiere visitar nuestra web en:',
    redirectButtonText: `Ir a ${getLanguageNameByCode(LanguageCode.es)}`,
    languageRegion: `${getLanguageNameByCode(LanguageCode.es)}`,
    stayRedirectButtonText: `Permanecer en ${getLanguageNameByCode(LanguageCode.es)}`,
    chooseBannerText: 'Elegir otra región o idioma',
};

const de: LanguageBannerTransalation = {
    pageText: 'Möchten Sie die Website aus Ihrer bevorzugten Land sehen:',
    redirectButtonText: `Zur ${getLanguageNameByCode(LanguageCode.de)}-Website wechseln`,
    languageRegion: `${getLanguageNameByCode(LanguageCode.de)}`,
    stayRedirectButtonText: `Auf der aktuellen Seite bleiben ${getLanguageNameByCode(LanguageCode.de)}`,
    chooseBannerText: 'Anderes Land/Sprache wählen',
};

const it: LanguageBannerTransalation = {
    pageText: 'Vuoi navigare il nostro sito in:',
    redirectButtonText: 'Andare sul sito per l\'Italia (Italiano)',
    languageRegion: `${getLanguageNameByCode(LanguageCode.it)}`,
    stayRedirectButtonText: 'Rimani sul sito internazionale (Inglese)',
    chooseBannerText: 'Scegli un\'altra regione/lingua',
};

const fr: LanguageBannerTransalation = {
    pageText: 'Souhaitez-vous visiter notre site dans une autre région:',
    redirectButtonText: `Aller à  ${getLanguageNameByCode(LanguageCode.frFr)}`,
    languageRegion: `${getLanguageNameByCode(LanguageCode.frFr)}`,
    stayRedirectButtonText: `Continuer sur le site ${getLanguageNameByCode(LanguageCode.frFr)}`,
    chooseBannerText: 'Choisir une autre région/langue',
};

const ptBr: LanguageBannerTransalation = {
    pageText: 'Você gostaria de visualizar nosso site na região de sua preferência:',
    redirectButtonText: `Ir para  ${getLanguageNameByCode(LanguageCode.ptBr)}`,
    languageRegion: `${getLanguageNameByCode(LanguageCode.ptBr)}`,
    stayRedirectButtonText: `Permanecer no site ${getLanguageNameByCode(LanguageCode.ptBr)}`,
    chooseBannerText: 'Escolha outra região/idioma',
};

const au: LanguageBannerTransalation = {
    pageText: 'Would you like to see our site in your preferred region:',
    redirectButtonText: `Go to ${getLanguageNameByCode(LanguageCode.enAu)}`,
    languageRegion: `${getLanguageNameByCode(LanguageCode.enAu)}`,
    stayRedirectButtonText: `Stay on ${getLanguageNameByCode(LanguageCode.enAu)}`,
    chooseBannerText: 'Choose another region/language',
};

const apac: LanguageBannerTransalation = {
    pageText: 'Would you like to see our site in your preferred region:',
    redirectButtonText: `Go to ${getLanguageNameByCode(LanguageCode.apac)}`,
    languageRegion: `${getLanguageNameByCode(LanguageCode.apac)}`,
    stayRedirectButtonText: `Stay on ${getLanguageNameByCode(LanguageCode.apac)}`,
    chooseBannerText: 'Choose another region/language',
};

const esMx: LanguageBannerTransalation = {
    pageText: '¿Le gustaría ver nuestro sitio en su región preferida:',
    redirectButtonText: `Ir a ${getLanguageNameByCode(LanguageCode.esMx)}`,
    languageRegion: `${getLanguageNameByCode(LanguageCode.esMx)}`,
    stayRedirectButtonText: `Permanecer en el sitio de ${getLanguageNameByCode(LanguageCode.esMx)}`,
    chooseBannerText: 'Elija otra región/idioma',
};

const pl: LanguageBannerTransalation = {
    pageText: 'Would you likeetch --all to see our site in your preferred region: ',
    redirectButtonText: `Przejdź do ${getLanguageNameByCode(LanguageCode.pl)}`,
    languageRegion: `${getLanguageNameByCode(LanguageCode.pl)}`,
    stayRedirectButtonText: `Pozostań na bieżącej stronie ${getLanguageNameByCode(LanguageCode.pl)}`,
    chooseBannerText: 'Wybierz inny kraj/język',
};

const nl: LanguageBannerTransalation = {
    pageText: 'Wilt u de website zien uit het land van uw voorkeur:',
    redirectButtonText: `Ga naar ${getLanguageNameByCode(LanguageCode.nl)}`,
    languageRegion: `${getLanguageNameByCode(LanguageCode.nl)}`,
    stayRedirectButtonText: `Blijf op ${getLanguageNameByCode(LanguageCode.nl)} site`,
    chooseBannerText: 'Kies een andere regio/taal',
};

const se: LanguageBannerTransalation = {
    pageText: 'Would you like to see our site in your preferred region:',
    redirectButtonText: `Gå till ${getLanguageNameByCode(LanguageCode.se)}`,
    languageRegion: `${getLanguageNameByCode(LanguageCode.se)}`,
    stayRedirectButtonText: `Stanna kvar på webbplatsen för ${getLanguageNameByCode(LanguageCode.se)}`,
    chooseBannerText: 'Välj en annan region/ett annat språk',
};

const ae: LanguageBannerTransalation = {
    pageText: 'Would you like to see our site in your preferred region:',
    redirectButtonText: `Go to ${getLanguageNameByCode(LanguageCode.ae)}`,
    languageRegion: `${getLanguageNameByCode(LanguageCode.ae)}`,
    stayRedirectButtonText: `Stay on ${getLanguageNameByCode(LanguageCode.ae)}`,
    chooseBannerText: 'Choose another region/language',
};

const ie: LanguageBannerTransalation = {
    pageText: 'Would you like to see our site in your preferred region:',
    redirectButtonText: `Go to ${getLanguageNameByCode(LanguageCode.ie)}`,
    languageRegion: `${getLanguageNameByCode(LanguageCode.ie)}`,
    stayRedirectButtonText: `Stay on ${getLanguageNameByCode(LanguageCode.ie)}`,
    chooseBannerText: 'Choose another region/language',
};

export const languageBannerTranslations: { [key: string]: LanguageBannerTransalation } = {
    [LanguageCode.en]: en,
    [LanguageCode.enGb]: uk,
    [LanguageCode.frCa]: frCa,
    [LanguageCode.es]: es,
    [LanguageCode.de]: de,
    [LanguageCode.it]: it,
    [LanguageCode.frFr]: fr,
    [LanguageCode.ptBr]: ptBr,
    [LanguageCode.enAu]: au,
    [LanguageCode.esMx]: esMx,
    [LanguageCode.pl]: pl,
    [LanguageCode.nl]: nl,
    [LanguageCode.se]: se,
    [LanguageCode.ae]: ae,
    [LanguageCode.ie]: ie,
    [LanguageCode.apac]: apac,
};
